import React from "react";

export default function Providers(props: { children: JSX.Element }): JSX.Element {
    const { children } = props;

    return (
        <>
            {children}
        </>
    );
}
