import React from "react";
import Layout from "../Layout";
import {useParams} from "react-router-dom";

export default function Inscription(): JSX.Element {
    const { id } = useParams<{ id: string }>();

    return (
        <Layout>
            <>
                <header className={"intro text-center pt-3 pb-5 mb-5"}>
                    <h1 className="h1 text-center text-white my-3">
                        Inscription {id}
                    </h1>
                </header>
                <div className="row">
                    <div className="col-md-4">
                        <div className="preview bg-secondary rounded text-center py-3 mb-3">
                            <img src={"https://ordinals.com/content/"+id} alt="Inscription Preview" className={"bg-white"} />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="traits mb-5">
                            <h3 className="h5 text-white">Traits</h3>
                            <div className="card bg-black border-0">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>ID</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={id}
                                                    readOnly={true}
                                                    disabled={true}
                                                    className="form-control p-0 border-0 bg-transparent"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="history">
                            <h3 className="h5 text-white">History</h3>
                            <div className="card bg-black border-0">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Action</th>
                                            <th>Account</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                            2009-01-29 01:24:53
                                            </td>
                                            <td>
                                            Minted
                                            </td>
                                            <td>
                                            1KYMaYnaXT2o57hy2tmZK5xWwgRmsyoHXo
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Layout>
    );
}
