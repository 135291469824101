import React, {useEffect, useState} from "react";
import Layout from "../Layout";
import bitcoinLogo from "../assets/images/bitcoin-logo-white.svg";
import bitcoinIcon from "../assets/images/bitcoin-icon.svg";
import bg from "../assets/images/bg1.png";
import allCollections from "../data/collections.json";
import ICollection from "../interfaces/ICollection";
import {Link} from "react-router-dom";
import collections from "../data/collections.json";

interface IQuerySuggestion {
    label: string
    path: string
}

export default function Home(): JSX.Element {
    const [query, setQuery] = useState<string>("");
    const [querySuggestions, setQuerySuggestions] = useState<IQuerySuggestion[]>([]);

    useEffect(() => {
        if (query.length < 3) {
            setQuerySuggestions([]);
        } else {
            const matchingCollections = Object.values(allCollections).filter((collection: ICollection) => collection.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()));

            console.log(
                matchingCollections
            );

            if (matchingCollections.length > 0) {
                setQuerySuggestions(
                    matchingCollections.map((collection: ICollection) => ({
                        label: collection.name+" ("+collection.items.length+" items)",
                        path: "/collections/" + collection.id
                    }))
                );
            }
        }
    }, [query]);

    return (
        <Layout>
            <>
                <header
                    className={"intro text-center py-5 mb-5"}
                    style={{
                        backgroundColor: "#402500",
                        backgroundImage: "url("+bg+")",
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "fixed",
                        backgroundSize: "cover",
                        backgroundPosition: "center center"
                    }}
                >
                    <h1 className="text-center text-white my-5">
                        Inscriptions are native NFTs on <img src={bitcoinLogo} alt="Bitcoin" style={{ height: "35px" }} />
                    </h1>
                </header>
                <div className="pt-3 pb-5 mb-5 text-center" style={{ borderBottom: "1px solid #333" }}>
                    <h3>
                        We are in alpha ATM
                    </h3>
                    <p className="lead">
                        We are building <u>the</u> explorer for Ordinals/NFTs on <img src={bitcoinIcon} alt="Bitcoin" style={{ height: "15px", margin: "-5px 0 0 5px" }} /> Bitcoin!
                    </p>
                    <p className="lead text-white mt-5">
                        Join us on Discord to follow the development 🕵️
                    </p>
                    <a href="https://discord.gg/7vgKtcTett" target={"_blank"} className="btn btn-primary" rel="noreferrer">
                        <i className="fab fa-discord" /> Discord
                    </a>
                </div>
                <header className={"explore text-center pt-3 pb-5 mb-5 d-none"}>
                    <h2 className="h1 text-center text-white my-3">
                        Find ordinals
                    </h2>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-8">
                            <div className="form-group">
                                <div className="controls">
                                    <input
                                        type="text"
                                        value={query}
                                        onChange={e => setQuery(e.target.value)}
                                        placeholder={"Enter collection name, inscription number, wallet address, or rarity"}
                                        className="form-control form-control-lg bg-white"
                                    />
                                </div>
                                {querySuggestions.length > 0 ? (
                                    <>
                                        <p className="small text-muted mt-3 mb-2">
                                            Suggestions:
                                        </p>
                                        <ul className="list-inline text-center">
                                            {querySuggestions.map((suggestion: IQuerySuggestion, index: number) => (
                                                <li key={index}>
                                                    <Link to={suggestion.path}>{suggestion.label}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                ) : <></>}
                            </div>
                        </div>
                    </div>
                </header>
                <div className="collections-filter pb-5 text-center">
                    <h2 className={"mb-3"}>
                        Collections
                    </h2>
                    <div className="collection-list">
                        <div className="row justify-content-center">
                            {Object.values(collections)
                                .map((collection: ICollection) => (
                                    <div key={collection.id} className="col-md-3">
                                        <Link
                                            to={"/collections/"+collection.id}
                                            className="collection"
                                        >
                                            {collection.settings?.coverImage && (
                                                <div className="bg">
                                                    <img src={"/assets/images/collections/"+collection.settings.coverImage} alt=""/>
                                                </div>
                                            )}
                                            <div className="content">
                                                <span className="collection-title">
                                                    {collection.name}
                                                </span>
                                                <span className="items-count">
                                                    {collection.items.length} ordinals
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div className="rarity-filter py-5 text-center d-none">
                    <h2 className={"mb-3"}>
                        Or filter by rarity
                    </h2>
                    <div className="categories row justify-content-center">
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/0%C2%B00%E2%80%B20%E2%80%B31%E2%80%B4" className={"rarity"}>
                                <span className="icon">😐 Common</span>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/0%C2%B01%E2%80%B21%E2%80%B30%E2%80%B4" className={"rarity"}>
                                <span className="icon">🙂 Uncommon</span>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/0%C2%B02016%E2%80%B20%E2%80%B30%E2%80%B4" className={"rarity"}>
                                <span className="icon">😏 Rare</span>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/0%C2%B00%E2%80%B2336%E2%80%B30%E2%80%B4" className={"rarity"}>
                                <span className="icon">🤤 Epic</span>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/1%C2%B00%E2%80%B20%E2%80%B30%E2%80%B4" className={"rarity"}>
                                <span className="icon">🥵 Legendary</span>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/0%C2%B00%E2%80%B20%E2%80%B30%E2%80%B4" className={"rarity"}>
                                <span className="icon">🤯 Mythic</span>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/satoshi" className={"rarity"}>
                                <span className="icon">🌴 Exotic</span>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        </Layout>
    );
}
