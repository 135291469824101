import React from "react";

export default function Footer(): JSX.Element {
    return (
        <>
            <footer className="text-center py-5 mt-5">
                <div className="container">
                    <p>ordinals rock :) - <a href="https://docs.ordinals.com/" target={"_blank"} rel="noreferrer">Docs</a></p>
                    <p
                        className="small"
                        style={{
                            color: "rgba(255, 255, 255, .25)"
                        }}
                    >
                        <small>
                            All images used on this website are for illustration purposes only and are the property of their respective owners. No copyright infringement is intended. If you own the rights to any of the images and do not wish them to appear on this website, please contact us.
                        </small>
                    </p>
                </div>
            </footer>
        </>
    );
}
