import React, {useEffect, useState} from "react";
import Layout from "../Layout";
import {useParams} from "react-router-dom";
import ICollection from "../interfaces/ICollection";
import allCollections from "../data/collections.json";

export default function CollectionItem(): JSX.Element {
    const { id, ordinalId } = useParams<{ id: string, ordinalId: string }>();

    const [collection, setCollection] = useState<ICollection | null>(null);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setCollection(allCollections[id] || null);
    }, [id]);

    return (
        <Layout>
            <>
                {collection === null ? <p>Loading...</p> : (
                    <>
                        <header className={"intro text-center pb-3 mb-3"}>
                            <h1 className="h1 text-center text-white my-3">
                                {collection.name}
                            </h1>
                        </header>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="preview rounded text-center mb-3">
                                    <img
                                        src={"https://ordinals.com/content/"+ordinalId}
                                        alt={ordinalId}
                                        className={"rounded w-100"}
                                        style={{
                                            backgroundColor: collection?.settings?.previewBg || "#fff"
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="traits mb-5">
                                    <h3 className="h5 text-white">Traits</h3>
                                    <div className="card bg-black border-0">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>ID</td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={ordinalId}
                                                            readOnly={true}
                                                            disabled={true}
                                                            className="form-control p-0 border-0 bg-transparent"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="history">
                                    <h3 className="h5 text-white">History</h3>
                                    <div className="card bg-black border-0">
                                        <div className="card-body text-center">
                                            <small>
                                                Coming soon
                                            </small>
                                        </div>
                                        <table className="table d-none">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Action</th>
                                                    <th>Account</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                    2009-01-29 01:24:53
                                                    </td>
                                                    <td>
                                                    Minted
                                                    </td>
                                                    <td>
                                                    1KYMaYnaXT2o57hy2tmZK5xWwgRmsyoHXo
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        </Layout>
    );
}
