import React from "react";
import Layout from "../Layout";

export default function Wallet(): JSX.Element {
    return (
        <Layout>
            <>
                <header className={"intro text-center pt-3 pb-5 mb-5"}>
                    <h1 className="h1 text-center text-white my-3">
                        My Inscriptions / Wallet
                    </h1>
                </header>
                <p className="lead text-center">
                    Coming soon
                </p>
            </>
        </Layout>
    );
}
