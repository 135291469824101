import React, {useEffect, useState} from "react";
import Layout from "../Layout";
import {Link, useParams} from "react-router-dom";
import allCollections from "../data/collections.json";
import ICollection from "../interfaces/ICollection";

export default function Collection(): JSX.Element {
    const { id } = useParams<{ id: string }>();

    const [collection, setCollection] = useState<ICollection | null>(null);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setCollection(allCollections[id] || null);
    }, [id]);

    return (
        <Layout>
            <>
                {collection === null ? <p>Loading...</p> : (
                    <>
                        <header className={"explore text-center py-3 mb-5"}>
                            <h1 className="h1 text-center text-white my-3">
                                {collection.name}
                            </h1>
                            <ul className="list-inline mb-3">
                                <li className="list-inline-item">
                                    <a
                                        href="https://twitter.com/OrdinalPunks"
                                        target={"_blank"}
                                        rel="noreferrer"
                                        className={"text-white"}
                                    >
                                        <i className="fab fa-twitter"/>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a
                                        href="https://discord.gg/enNZ8SkMa3"
                                        target={"_blank"}
                                        rel="noreferrer"
                                        className={"text-white"}
                                    >
                                        <i className="fab fa-discord"/>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a
                                        href="https://ordinalpunks.com/"
                                        target={"_blank"}
                                        rel="noreferrer"
                                        className={"text-white"}
                                    >
                                        <i className="fas fa-globe"/>
                                    </a>
                                </li>
                            </ul>
                            <div className="row justify-content-center">
                                <div className="col-md-4">
                                    <p className="display-6 text-white mb-0">
                                        {collection.items.length}
                                    </p>
                                    <p className="lead">
                                        Ordinals
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <p className="display-6 text-white mb-0">
                                        {collection.information?.mintPrice || "Unknown"}
                                    </p>
                                    <p className="lead">
                                        Mint price (BTC)
                                    </p>
                                </div>
                            </div>
                        </header>
                        <div className="collection-gallery">
                            {collection.items.length === 0 ? <p className={"lead text-center"}>No items in this collection</p> : (
                                <>
                                    <div className="row">
                                        {collection.items.map((inscriptionId: string, index: number) => (
                                            <div key={inscriptionId} className={"col-md-3 mb-4"}>
                                                <div className="text-center">
                                                    <div className="preview mb-2">
                                                        <Link
                                                            to={"/collections/"+collection.id+"/"+inscriptionId}
                                                        >
                                                            <img
                                                                src={"https://ordinals.com/content/"+inscriptionId}
                                                                alt={inscriptionId}
                                                                className={"rounded"}
                                                                style={{
                                                                    backgroundColor: collection?.settings?.previewBg || "#fff"
                                                                }}
                                                            />
                                                        </Link>
                                                    </div>
                                                    <p className="text-white mb-0">
                                                        {collection.ordinalName.singular} #{index+1}
                                                    </p>
                                                    <Link
                                                        to={"/collections/"+collection.id+"/"+inscriptionId}
                                                        className="btn btn-link"
                                                    >View</Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </>
        </Layout>
    );
}
