import React from "react";

import { BrowserRouter, Routes as Switch, Route } from "react-router-dom";

// Pages
import Home from "./pages/Home";
import Wallet from "./pages/Wallet";
import Inscription from "./pages/Inscription";
import Explorer from "./pages/Explorer";
import Collections from "./pages/Collections";
import Collection from "./pages/Collection";
import CollectionItem from "./pages/CollectionItem";
import Donate from "./pages/Donate";

export interface IRoute {
    path: string
    children: JSX.Element
}

export const RouteList: { [routeName: string]: IRoute } = {
    home: {
        path: "/",
        children: <Home />
    },
    explorer: {
        path: "/explorer",
        children: <Explorer />
    },
    collections: {
        path: "/collections",
        children: <Collections />
    },
    collection: {
        path: "/collections/:id",
        children: <Collection />
    },
    collectionItem: {
        path: "/collections/:id/:ordinalId",
        children: <CollectionItem />
    },
    account: {
        path: "/address/:address",
        children: <Wallet />
    },
    ordinal: {
        path: "/inscriptions/:id",
        children: <Inscription />
    },
    donate: {
        path: "/donate",
        children: <Donate />
    },
    wallet: {
        path: "/wallet",
        children: <Wallet />
    },
};

export default function Routes(): JSX.Element {
    return (
        <>
            <BrowserRouter>
                <Switch>
                    {Object.values(RouteList)
                        .map((route: IRoute) => <Route key={route.path} path={route.path} element={route.children} />)
                    }
                </Switch>
            </BrowserRouter>
        </>
    );
}
