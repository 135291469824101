import React from "react";
import Layout from "../Layout";

export default function Explorer(): JSX.Element {
    return (
        <Layout>
            <>
                <header className={"explore text-center pt-3 pb-5 mb-5"}>
                    <h2 className="h1 text-center text-white my-3">
                        Explore ordinals/inscriptions
                    </h2>
                    <p className="lead text-center">
                        Enter an inscription number or owner&apos;s address to find ordinals
                    </p>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-8">
                            <div className="form-group">
                                <div className="controls">
                                    <input
                                        type="text"
                                        placeholder={"Enter inscription number, wallet address, or rarity"}
                                        className="form-control form-control-lg bg-white"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <p className="lead text-center">
                    Inscriptions
                </p>
                <div className="rarity-filter text-center">
                    <h2>
                        Or filter by rarity
                    </h2>
                    <div className="categories row justify-content-center">
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/0%C2%B00%E2%80%B20%E2%80%B31%E2%80%B4" className={"rarity"}>
                                <span className="icon">😐 Common</span>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/0%C2%B01%E2%80%B21%E2%80%B30%E2%80%B4" className={"rarity"}>
                                <span className="icon">🙂 Uncommon</span>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/0%C2%B02016%E2%80%B20%E2%80%B30%E2%80%B4" className={"rarity"}>
                                <span className="icon">😏 Rare</span>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/0%C2%B00%E2%80%B2336%E2%80%B30%E2%80%B4" className={"rarity"}>
                                <span className="icon">🤤 Epic</span>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/1%C2%B00%E2%80%B20%E2%80%B30%E2%80%B4" className={"rarity"}>
                                <span className="icon">🥵 Legendary</span>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/0%C2%B00%E2%80%B20%E2%80%B30%E2%80%B4" className={"rarity"}>
                                <span className="icon">🤯 Mythic</span>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://ordinals.com/ordinal/satoshi" className={"rarity"}>
                                <span className="icon">🌴 Exotic</span>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        </Layout>
    );
}
