import React from "react";
import Layout from "../Layout";
import bitcoinLogo from "../assets/images/bitcoin-logo-white.svg";

export default function Donate(): JSX.Element {
    return (
        <Layout>
            <>
                <header className={"intro text-center py-3"}>
                    <h2 className="h1 text-center text-white my-3">
                        Support our work 🙏
                    </h2>
                </header>
                <p className="text-center lead">
                    OrdinalExplorer.com is built by and paid for volunteers, and completely <u>free</u> to use.
                </p>
                <p className="text-center lead">
                    We will never ask for money to use OrdinalExplorer, but welcome any kind of support to keep up the pace.
                </p>
                <p className="text-center lead mt-5">
                    If you want to help us accelerate the development, we would love for you to support us! 🙏
                </p>
                <p className="text-center text-secondary mt-5">
                    Let&apos;s accelerate the adoption of NFTs on <img src={bitcoinLogo} alt="Bitcoin" style={{ height: "15px", margin: "-5px 5px 0" }} /> together!
                </p>
                <p className="text-white text-center mt-5">
                    Donation addresses:
                </p>
                <ul className="list-unstyled text-center">
                    <li className={"mb-3"}>
                        Bitcoin:
                        <br />
                        bc1qz7lnkh5nvhvtd4uzfrzwcmr89vul8tjt3kf8g6
                    </li>
                    <li>
                        Ethereum:
                        <br />
                        0x879f8c1AA2E75D17ccd6fb45c70316f0A7dB7616
                    </li>
                </ul>
            </>
        </Layout>
    );
}
