import React from "react";

import {Nav, Navbar as BSNavbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import {RouteList} from "../Routes";
import logo from "../assets/images/logo.svg";

export default function Navbar(): JSX.Element
{
    return (
        <>
            <BSNavbar
                expand="lg"
                className={"bg-transparent navbar-dark"}
            >
                <div className={"container"}>
                    <Link to={RouteList.home.path} className="navbar-brand">
                        <img
                            src={logo}
                            alt="Ordinals Explorer"
                            style={{
                                height: "30px"
                            }}
                            className={"me-1"}
                        />
                    </Link>
                    <BSNavbar.Toggle aria-controls="basic-navbar-nav" />
                    <BSNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <li className="nav-item">
                                <Link to={RouteList.home.path} className={"nav-link"+" "+(RouteList.home.path == window.location.pathname ? "active" : "")}>
                                    🔎 Explore
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={RouteList.collections.path} className={"nav-link"+" "+(RouteList.collections.path == window.location.pathname ? "active" : "")}>
                                    🖼️ Collections
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={RouteList.wallet.path} className={"nav-link"+" "+(RouteList.wallet.path == window.location.pathname ? "active" : "")}>
                                    👤 My Inscriptions / Wallet
                                </Link>
                            </li>
                        </Nav>
                        <Nav className="ms-auto">
                            <li className="nav-item">
                                <Link to={RouteList.donate.path} className={"nav-link"+" "+(RouteList.donate.path == window.location.pathname ? "active" : "")}>
                                    🤝 Donation
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href={"#"} className={"nav-link"}>
                                    Exchange <span className="badge bg-black small fw-normal text-muted ps-0">soon :)</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href={"https://docs.ordinals.com/"} target={"_blank"} className={"nav-link"} rel="noreferrer">
                                    Docs
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    href={"https://twitter.com/OrdinalExplorer"}
                                    target={"_blank"}
                                    className="nav-link"
                                    rel="noreferrer"
                                ><i className="fab fa-twitter" /></a>
                            </li>
                            <li className="nav-item">
                                <a
                                    href={"https://discord.gg/7vgKtcTett"}
                                    target={"_blank"}
                                    className="nav-link"
                                    rel="noreferrer"
                                ><i className="fab fa-discord" /></a>
                            </li>
                        </Nav>
                    </BSNavbar.Collapse>
                </div>
            </BSNavbar>
        </>
    );
}
