import React from "react";
import Layout from "../Layout";
import {Link} from "react-router-dom";
import collections from "../data/collections.json";
import ICollection from "../interfaces/ICollection";

export default function Collections(): JSX.Element {

    return (
        <Layout>
            <>
                <header className={"explore text-center pt-3 pb-5 mb-5"}>
                    <h1 className="h1 text-center text-white my-3">
                        Collections
                    </h1>
                </header>
                <div className="collection-list">
                    <div className="row justify-content-center">
                        {Object.values(collections)
                            .map((collection: ICollection) => (
                                <div key={collection.id} className="col-md-3">
                                    <Link
                                        to={"/collections/"+collection.id}
                                        className="collection"
                                    >
                                        {collection.settings?.coverImage && (
                                            <div className="bg">
                                                <img src={"/assets/images/collections/"+collection.settings.coverImage} alt=""/>
                                            </div>
                                        )}
                                        <div className="content">
                                            <span className="collection-title">
                                                {collection.name}
                                            </span>
                                            <span className="items-count">
                                                {collection.items.length} ordinals
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                    </div>
                </div>
            </>
        </Layout>
    );
}
